<template>
  <div v-if="loadingContent" class="text-center">
    <b-spinner variant="primary" label="Text Centered" />
  </div>
  <div v-else>
    <h3>รายละเอียดการแจ้งโอนเงิน</h3>

    <b-row>
      <!-- <b-col cols="1"></b-col> -->
      <b-col cols="8">
        <b-card no-body class="card-apply-job">
          <b-card-header class="pb-1">
            <b-media no-body>
              <!-- <b-media-aside class="mr-1">
                <b-avatar
                  size="42"
                  :src="
                    require('@/assets/images/portrait/small/avatar-s-20.jpg')
                  "
                />
              </b-media-aside> -->
              <b-media-body>
                <h3 class="mb-0">
                  บัญชีผู้ใช้ (Username) :
                  {{
                    dataDetail &&
                    dataDetail.customerRefUser &&
                    dataDetail.customerRefUser !== ""
                      ? dataDetail.customerRefUser
                      : ""
                  }}
                </h3>
                <h5 class="text-muted" style="margin-top: 5%">
                  วันที่โอนเงิน :
                  {{
                    moment(dataDetail.transferDateTime).locale("th").format(
                      "Do MMMM YYYY HH:mm น."
                    )
                  }}
                </h5>
              </b-media-body>
            </b-media>
            <b-badge
              pill
              variant="light-primary"
              v-if="dataDetail.status === 'new'"
            >
              รายการใหม่
            </b-badge>
            <b-badge
              pill
              variant="light-success"
              v-else-if="dataDetail.status === 'success'"
            >
              ข้อมูลถูกต้อง
            </b-badge>
            <b-badge
              pill
              variant="light-success"
              v-else-if="dataDetail.status === 'reject'"
            >
              ไม่ผ่านการตรวจสอบ
            </b-badge>
          </b-card-header>
          <b-card-body>
            <b-card-text class="mb-2">
              <!-- <p v-if="dataDetail.ownBank && dataDetail.ownBank !== ''">
                ธนาคาร : {{ dataDetail.ownBank }}
              </p> -->
              <p
                v-if="
                  dataDetail.customerPhone && dataDetail.customerPhone !== ''
                "
              >
                หมายเลขโทรศัพท์ : {{ dataDetail.customerPhone }}
              </p>
              <p
                v-if="
                  (dataDetail.customerAddress &&
                    dataDetail.customerAddress !== '') ||
                    (dataDetail.customerDistirict &&
                      dataDetail.customerDistirict !== '') ||
                    (dataDetail.customerSubDistirict &&
                      dataDetail.customerSubDistirict !== '') ||
                    (dataDetail.customerProvince &&
                      dataDetail.customerProvince !== '') ||
                    (dataDetail.customerPostCode &&
                      dataDetail.customerPostCode !== '')
                "
              >
                ที่อยู่ : {{ dataDetail.address }}
              </p>
            </b-card-text>

            <!-- payment  -->
            <div class="apply-job-package bg-light-primary rounded">
              <h2 class="text-body">
                <span>ยอดเงินที่โอน :</span>
              </h2>
              <h2 class="d-inline mr-25">
                {{ dataDetail.transferAmount }}
                บาท
              </h2>
            </div>
            <hr />

            <h4>สลิปเงิน</h4>
            <div
              style="text-align: center;"
              v-if="dataDetail.fileUpload && dataDetail.fileUpload !== ''"
            >
              <b-img :src="dataDetail.fileUpload" width="40%" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col cols="1"></b-col> -->
      <b-col cols="4">
        <timeline
          :refId="
            dataDetail && dataDetail.ref && dataDetail.ref !== ''
              ? dataDetail.ref
              : ''
          "
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import BaseAPIURL from "@core/app-config/baseAPIURL";
import Timeline from "../components/Timeline.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
    BCardText,
    BCardBody,
    BButton,
    BImg,
    BSpinner,
    Timeline,
  },
  data() {
    return {
      moment,
      loadingContent: false,
      dataDetail: {},
    };
  },
  created() {
    this.fetchData(this.$route.params.id);
  },
  methods: {
    fetchData(id) {
      this.loadingContent = true;
      useJwt
        .get("/transfers", id)
        .then((res) => {
          console.log("res fetch", res);
          if (res.status === 200 && res.data) {
            this.dataDetail = {
              ownBank: res.data.ownBank ? res.data.ownBank : "",
              fileUpload:
                res.data.fileUpload &&
                res.data.fileUpload.length > 0 &&
                res.data.fileUpload[0] &&
                res.data.fileUpload[0].url &&
                res.data.fileUpload[0].url !== ""
                  ? BaseAPIURL + res.data.fileUpload[0].url
                  : "",
              status: res.data.status ? res.data.status : "",
              transferAmount: res.data.transferAmount
                ? res.data.transferAmount
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                : "",
              transferDateTime: res.data.transferDateTime
                ? res.data.transferDateTime
                : "",
              customerName: res.data.customerName ? res.data.customerName : "",
              customerPhone: res.data.customerPhone
                ? res.data.customerPhone
                : "",
              customerRefUser: res.data.customerRefUser
                ? res.data.customerRefUser
                : "",
              address: `${
                res.data.customerAddress ? res.data.customerAddress : ""
              } อ.${
                res.data.customerDistirict ? res.data.customerDistirict : ""
              } ต.${
                res.data.customerSubDistirict
                  ? res.data.customerSubDistirict
                  : ""
              } จ.${
                res.data.customerProvince ? res.data.customerProvince : ""
              } รหัสไปรษณีย์${
                res.data.customerPostCode ? res.data.customerPostCode : ""
              }`,
              customerAddress: res.data.customerAddress
                ? res.data.customerAddress
                : "",
              customerDistirict: res.data.customerDistirict
                ? res.data.customerDistirict
                : "",
              customerSubDistirict: res.data.customerSubDistirict
                ? res.data.customerSubDistirict
                : "",
              customerProvince: res.data.customerProvince
                ? res.data.customerProvince
                : "",
              customerPostCode: res.data.customerPostCode
                ? res.data.customerPostCode
                : "",
              ref: res.data.ref ? res.data.ref : "",
            };
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("dataDetail", this.dataDetail);
          this.loadingContent = false;
        });
    },
  },
};
</script>

<style scoped>
.row-content {
  margin-top: 2%;
}
</style>
