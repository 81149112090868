var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingContent)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_c('div',[_c('h3',[_vm._v("รายละเอียดการแจ้งโอนเงิน")]),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-card',{staticClass:"card-apply-job",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-body',[_c('h3',{staticClass:"mb-0"},[_vm._v(" บัญชีผู้ใช้ (Username) : "+_vm._s(_vm.dataDetail && _vm.dataDetail.customerRefUser && _vm.dataDetail.customerRefUser !== "" ? _vm.dataDetail.customerRefUser : "")+" ")]),_c('h5',{staticClass:"text-muted",staticStyle:{"margin-top":"5%"}},[_vm._v(" วันที่โอนเงิน : "+_vm._s(_vm.moment(_vm.dataDetail.transferDateTime).locale("th").format( "Do MMMM YYYY HH:mm น." ))+" ")])])],1),(_vm.dataDetail.status === 'new')?_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" รายการใหม่ ")]):(_vm.dataDetail.status === 'success')?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" ข้อมูลถูกต้อง ")]):(_vm.dataDetail.status === 'reject')?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" ไม่ผ่านการตรวจสอบ ")]):_vm._e()],1),_c('b-card-body',[_c('b-card-text',{staticClass:"mb-2"},[(
                _vm.dataDetail.customerPhone && _vm.dataDetail.customerPhone !== ''
              )?_c('p',[_vm._v(" หมายเลขโทรศัพท์ : "+_vm._s(_vm.dataDetail.customerPhone)+" ")]):_vm._e(),(
                (_vm.dataDetail.customerAddress &&
                  _vm.dataDetail.customerAddress !== '') ||
                  (_vm.dataDetail.customerDistirict &&
                    _vm.dataDetail.customerDistirict !== '') ||
                  (_vm.dataDetail.customerSubDistirict &&
                    _vm.dataDetail.customerSubDistirict !== '') ||
                  (_vm.dataDetail.customerProvince &&
                    _vm.dataDetail.customerProvince !== '') ||
                  (_vm.dataDetail.customerPostCode &&
                    _vm.dataDetail.customerPostCode !== '')
              )?_c('p',[_vm._v(" ที่อยู่ : "+_vm._s(_vm.dataDetail.address)+" ")]):_vm._e()]),_c('div',{staticClass:"apply-job-package bg-light-primary rounded"},[_c('h2',{staticClass:"text-body"},[_c('span',[_vm._v("ยอดเงินที่โอน :")])]),_c('h2',{staticClass:"d-inline mr-25"},[_vm._v(" "+_vm._s(_vm.dataDetail.transferAmount)+" บาท ")])]),_c('hr'),_c('h4',[_vm._v("สลิปเงิน")]),(_vm.dataDetail.fileUpload && _vm.dataDetail.fileUpload !== '')?_c('div',{staticStyle:{"text-align":"center"}},[_c('b-img',{attrs:{"src":_vm.dataDetail.fileUpload,"width":"40%"}})],1):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('timeline',{attrs:{"refId":_vm.dataDetail && _vm.dataDetail.ref && _vm.dataDetail.ref !== ''
            ? _vm.dataDetail.ref
            : ''}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }