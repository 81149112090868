<template>
  <b-row>
    <b-col cols="12">
      <payment-detail />
    </b-col>
  </b-row>
</template>

<script>
import paymentDetail from "./detail.vue";
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,

    paymentDetail,
  },
};
</script>

<style></style>
