<template>
  <b-card>
    <app-timeline>
      <app-timeline-item
        v-for="(item, index) in timelineList"
        :key="index"
        :variant="
          item.status === 'new'
            ? 'info'
            : item.status === 'completed'
              ? 'success'
              : item.status === 'checked'
                ? 'primary'
                : item.status === 'rejected'
                  ? 'danger'
                  : ''
        "
      >
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>{{ item.title }}</h6>
          <small class="text-muted">{{
            moment(item.created_at).locale("th").fromNow()
          }}</small>
        </div>
        <p>{{ item.message }}</p>
        <span v-if="item.status === 'new'">
          <b-badge
            variant="info"
            class="badge-glow"
          >
            รายการใหม่
          </b-badge>
        </span>
        <span v-else-if="item.status === 'checked'">
          <b-badge
            variant="primary"
            class="badge-glow"
          >
            ผ่านการตรวจสอบ
          </b-badge>
        </span>
        <span v-else-if="item.status === 'completed'">
          <b-badge
            variant="success"
            class="badge-glow"
          >
            ข้อมูลถูกต้อง
          </b-badge>
        </span>
        <span v-else-if="item.status === 'rejected'">
          <b-badge
            variant="danger"
            class="badge-glow"
          >
            ไม่ผ่านการตรวจสอบ
          </b-badge>
        </span>
        <!-- <span v-else-if="item.status === 'waiting'">
          <b-badge variant="warning" class="badge-glow">
            รอดำเนินการ
          </b-badge>
        </span> -->
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { BCard, BBadge } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BBadge,
    BCard,
  },
  props: {
    refId: String,
  },
  data() {
    return {
      timelineList: [],
      moment,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingContent = true
      useJwt
        .query('/transfer-logs', `transferRef=${this.refId}&_sort=created_at:ASC`)
        .then(res => {
          console.log('res fetch', res)
          if (res.status === 200 && res.data) {
            this.timelineList = res.data
          } else {
            throw res.data.message
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          console.log('timelineList', this.timelineList)
          this.loadingContent = false
        })
    },
  },
}
</script>
