var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('app-timeline',_vm._l((_vm.timelineList),function(item,index){return _c('app-timeline-item',{key:index,attrs:{"variant":item.status === 'new'
          ? 'info'
          : item.status === 'completed'
            ? 'success'
            : item.status === 'checked'
              ? 'primary'
              : item.status === 'rejected'
                ? 'danger'
                : ''}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v(_vm._s(item.title))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.moment(item.created_at).locale("th").fromNow()))])]),_c('p',[_vm._v(_vm._s(item.message))]),(item.status === 'new')?_c('span',[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"info"}},[_vm._v(" รายการใหม่ ")])],1):(item.status === 'checked')?_c('span',[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"primary"}},[_vm._v(" ผ่านการตรวจสอบ ")])],1):(item.status === 'completed')?_c('span',[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"success"}},[_vm._v(" ข้อมูลถูกต้อง ")])],1):(item.status === 'rejected')?_c('span',[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"danger"}},[_vm._v(" ไม่ผ่านการตรวจสอบ ")])],1):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }